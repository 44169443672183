@import "../../Components/SCSS/mixins";

.root {
  height: 100%;
}

.stuInfo {
  background-color: #FFF;
}

ul {
  padding: 0 rpx(32);
  margin: 0;
  width: 100%;
}

ul > li {
  padding: rpx(30) 0;
  margin-bottom: 0;
  display: flex;
  border-bottom: rpx(1) solid #EFEFEF;

  &:last-child {
    border-bottom: none;
  }
}

.left {
  flex: 1;
  line-height: rpx(44);
  font-size: rpx(32);
  font-weight: 700;
  color: #333;
}

.right {
  flex: 5;
  line-height: rpx(44);
  color: #666;
  font-size: rpx(28);
  text-align: right;
}
