@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.reportStepDiv {
  position: relative;
  margin-top: rpx(32);
  width: 100%;
  height: rpx(582);
  background: url("img/线上报到当天bg.png")no-repeat center top;
  background-size: 100% rpx(582);
}

.button{
  margin-top: rpx(466);
  @include beautiful-button(366, 84);//按钮特殊传对应px的数字
  font-size: rpx(32);
}

.alreadyButton {
  display: inline-block!important;
  margin-top: rpx(466);
  @include already-button(366, 84);
  font-size: rpx(32);
  color: #E13F3F;
}


