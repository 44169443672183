@import "../../../Components/SCSS/mixins";

.root{
  padding: rpx(16 * 2) rpx(16 * 2) 0 rpx(16 * 2);
  background-color: #F6F6F6;

  :global{
    .za-select__input{
      padding: 0 rpx(20 * 2) 0 0;

      &:after{
        top: rpx(6 * 2);
      }
    }

    .za-input input{
      text-align: right;
    }
  }
}



.name{
  line-height: rpx(22 * 2);
  font-size: rpx(16 * 2);
  color:#333333;;

  span{
    vertical-align: middle;
  }
}

.large{
  flex: 2 0 auto!important;
}


.cell{
  &:last-child{
    border: none;
  }
}

.textArea{
  display: block!important;
}

.right{
  margin-top: rpx(6 * 2);
}

.input{
  text-align: right;
}

.required{
  padding-right: 15px;
  background: url("img/red.png") right center no-repeat;
  background-size: rpx(8 * 2);
}

.member{
  position: relative;
  padding-top: rpx(29 * 2);
}

.dashed{
  border-bottom: 1px dashed #888888!important;
}

.tip{
  padding: rpx(5 * 2) 0 rpx(29 * 2) 0;
  font-weight: bold;
  font-size: rpx(12 * 2);
  color: #E13F3F;
}

.toastContent{
  align-self: center;
  padding: 0 rpx(26 * 2);
  line-height: rpx(30 * 2);
  font-size: rpx(14 * 2);
  color: #222222;
  font-weight: bold;
}

.toastRow{
  position: relative;

  //&:before{
  //  content: '';
  //  position: absolute;
  //  top: rpx(4 * 2);
  //  width: rpx(12 * 2);
  //  height: rpx(12 * 2);
  //  border-radius: 50%;
  //  background-color: #FFE4D3;
  //  z-index: -1;
  //}
}

.btn{
  margin: rpx(26 * 2) 0 rpx(46 * 2) 0;
  @include beautiful-button(386, 84);
}
