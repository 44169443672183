@import "src/Components/SCSS/mixins";

.root {
  min-height: 100vh;
  padding: rpx(18) rpx(42) 0;
  background-color: #FFF;
}

.empty {
  margin-top: rpx(22);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nameAndNo {
  line-height: rpx(40);
  color: #555555;
  font-size: rpx(28);
}

.stuNo {
  margin-bottom: rpx(96);
  color: #999999;
}

.button1 {
  margin-top: rpx(30);
  @include beautiful-button(288, 84);
  font-size: rpx(32);
}

.card {
  width: rpx(662);
  min-height: rpx(1006);
  background: url("./img/classbg.png") no-repeat center center;
  background-size: rpx(662) 100%;
  padding: rpx(60) rpx(72);
}

.header {
  display: flex;
}

.avater {
  width: rpx(92);
  height: rpx(92);
  border: rpx(6) solid rgb(255, 255, 255, .4);
  border-radius: 50%;
  overflow: hidden;
}

.avaterPic {
  width: 100%;
}

.stuInfo {
  margin-left: rpx(20);
  color: #FFF;
}

.name {
  line-height: rpx(50);
  font-size: rpx(36);
}

.no {
  line-height: rpx(34);
  font-size: rpx(24);
}

.content {
  margin-top: rpx(30);
}

.item{
  margin-bottom: rpx(8);
  line-height: rpx(40);
  font-size: rpx(28);
  list-style: none;
  color: #FFF;
}

.fontContent {
  display: inline-block;
  vertical-align: top;
  width: rpx(400);
}

.width300 {
  width: rpx(300);
}

.outerRing {
  display: inline-block;
  width: rpx(16);
  height: rpx(16);
  margin-right: rpx(16);
  border-radius: 50%;
  background-color: rgb(255, 255, 255, 0.4);
}

.innerRing {
  width: rpx(8);
  height: rpx(8);
  margin: rpx(4);
  border-radius: 50%;
  background-color: #FFD3A9;
}

.label {
  display: inline-block;
}

.font {
  display: inline-block;
}

.line {
  margin: rpx(32) 0 rpx(30);
  border-bottom: rpx(8) dotted #FFFFFF;
  opacity: 0.3;
  transform: scaleX(1.1);
}

.footer {
  display: flex;
}

.erweima {
  width: rpx(210);
  height: rpx(210);
  margin: 0 rpx(70) rpx(10) rpx(8);
  background-color: #D8D8D8;
  border-radius: rpx(12);
  overflow: hidden;
}

.erweimaPic {
  width: 100%;
}

.weiChatFont {
  width: rpx(224);
  line-height: rpx(40);
  font-size: rpx(24);
  color: #FFF;
  text-align: center;
}

.finger {
  width: rpx(124);
  margin: rpx(22) 0 rpx(14) 0;
}

.scaleFont {
  width: rpx(216);
  line-height: rpx(34);
  color: #FFF;
  font-size: rpx(24);
}

.button2 {
  margin-left: rpx(116);
  margin-top: rpx(30);
  @include beautiful-button(430, 80);
  font-size: rpx(32);
}
