@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.reportStepDiv {
  position: relative;
  margin-top: rpx(32);
  width: 100%;
  //height: rpx(1302);
  //background: url("./img/reportStepBg.png")no-repeat center top;
  //background-size: 100% rpx(1302);
  height: rpx(930);
  background: url("./img/reportStepBg2.png")no-repeat center top;
  background-size: 100% rpx(930);
  padding-left: rpx(68);
}

.step1Div {
  position: absolute;
  top: rpx(306);
  left: rpx(72);
  text-align: left;

}

.stepFont {
  width: rpx(290);
  line-height: rpx(44);
  color: #000000;
  font-weight: 700;
  font-size: rpx(32);
}

.button{
  margin-top: rpx(22);
  @include beautiful-button(160, 56);//按钮特殊传对应px的数字
  font-size: rpx(24);
}

.alreadyButton {
  margin-top: rpx(22);
  font-size: rpx(24);
  color: #E13F3F;
  @include already-button(160, 56);
}


.stepImgDiv {
  position: relative;

  .stepImg {
    z-index: 1;
    position: absolute;
    top: rpx(-50);
    left: rpx(300);
    width: rpx(294);
    height: rpx(284);
  }

  .shadowImg {
    z-index: 2;
    position: absolute;
    top: rpx(-50);
    left: rpx(265);
    width: rpx(348);
    height: rpx(324);
  }
}

.step2Div {
  position: absolute;
  top: rpx(654);
  left: rpx(72);
  text-align: left;
}

.step2Img {
  width: rpx(318)!important;
  height: rpx(246)!important;
}

.step3Div {
  position: absolute;
  top: rpx(928);
  left: rpx(72);
  text-align: left;
}
