@import "../../../Components/SCSS/mixins";


.root{
  width: 100%;
  min-height: 100vh;
  background: #F6F6F6;
}

.photoCollectionDiv {
  padding: 0 rpx(40) rpx(40) rpx(40);
}

.tip {
  padding: rpx(32) 0;
  line-height: rpx(34);
  font-size: rpx(24);
  color: #FF0000;
  text-align: left;
}

.greenTip {
  position: relative;
  padding-left: rpx(40);
  height: rpx(92);
  line-height: rpx(92);
  font-size: rpx(24);
  color: #60BA00;
  text-align: left;

  &:before {
    content: "";
    position: absolute;
    width: rpx(28);
    height: rpx(28);
    top: rpx(34);
    left: 0;
    background: url("./img/green.png")no-repeat center center;
    background-size: rpx(28) rpx(28);
  }
}

.photoContent {
  background: #FFFFFF;
  border-radius: rpx(20);
  padding-bottom: rpx(52);

}

.paddingTop {
  padding-top: rpx(100);
}

.photoTip {
  padding: rpx(16) rpx(28);
  background: #FFF5DA;
  border-radius: rpx(20) rpx(20) 0 0;
}

.tipFont {
  font-size: rpx(24);
  color: #FE8141;
  line-height: rpx(34);
}


.imgDiv {
  position: relative;
  overflow: hidden;

  .failShadow {
    position: absolute;
    top: rpx(16);
    left: 50%;
    transform: translateX(-50%);
    height: rpx(320);
    width: rpx(240);
    z-index: 3;
  }

  .passDiv {
    width: rpx(240);
    height: rpx(368);
  }

  .failPass {
    position: absolute;
    top: rpx(16);
    left: 50%;
    transform: translateX(-50%);
    height: rpx(320);
    width: rpx(240);
    z-index: 7;
  }
  //通过后的头像
  .passImg {
    position: absolute;
    top: rpx(16);
    left: 50%;
    transform: translateX(-50%);
    height: rpx(320);
    width: rpx(240);
    border-radius: rpx(16);
    z-index: 3;
  }
}
//上传头像样式start
.uploadImg {
  position: relative;
  margin: 0 auto;
  width: rpx(240);
  min-height: rpx(368);
  background: url("./img/imgBg.png")no-repeat center rpx(68);
  background-size: rpx(240) rpx(240);

  img{
    position: absolute;
    top: rpx(16);
    left: 50%;
    transform: translateX(-50%);
    min-height: rpx(320);
    width: rpx(240);
    border-radius: rpx(16);
  }
}
//上传头像样式end

.uploadButton {
  position: relative;
  text-align: center;

  input {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    width: rpx(430);
    height: rpx(84);
    z-index: 5;
  }
}

.button{
  @include beautiful-button(430, 84);//按钮特殊传对应px的数字
  font-size: rpx(32);
}

.uploadTip {
  margin-top: rpx(28);
  padding: 0 rpx(100);
  font-size: rpx(24);
  color: #303030;
  line-height: rpx(34);
  text-align: center;
}

.closeIcon {
  width: rpx(50);
  height: rpx(50);
  position: absolute;
  right: rpx(220);
  top: rpx(20);
  z-index: 3;
}
