@import "src/Components/SCSS/mixins";

.root{
  width: rpx(686);
  border-radius: rpx(20);
  box-sizing: border-box;
  background-color: #fff;
  background-image: url("./img/redCor.png");
  background-repeat: no-repeat;
  background-size: rpx(8);
}
