@import "src/Components/SCSS/mixins";

.container {
  padding: rpx(32);
}

.header {
  display: flex;
  padding-bottom: rpx(16);
  border-bottom: rpx(2) #F6F6F6 solid;
}

.title {
  line-height: rpx(44);
  max-width: rpx(492);
  color: #333;
  font-size: rpx(32);
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.stuNo {
  height: rpx(36);
  line-height: rpx(32);
  margin: rpx(4) 0 0 rpx(12);
  font-size: rpx(20);
  background: #F2F2F2;
  border: rpx(2) solid #A7A7A7;
  border-radius: rpx(6);
  padding: 0 rpx(12);
  color: #858585;
}

.content {
  padding: 0;
  margin: rpx(10) 0 0 0;
  line-height: rpx(40);
  color: #555;
  font-size: rpx(28);
}

.item {
  padding-top: rpx(12);
  padding-left: rpx(26);
  position: relative;

  &:before {
    content: "";
    height: 110%;
    border-left: rpx(1) dashed #E13F3F;
    position: absolute;
    top: rpx(28);
    left: rpx(2);
  }
}

.classMate {
  padding-top: rpx(12);
  padding-left: rpx(26);
  position: relative;
}

.dot {
  width: rpx(8);
  height: rpx(8);
  position: absolute;
  top: rpx(28);
  left: rpx(0);
  border-radius: 50%;
  background-color: #E13F3F;
}

.line {
  width: rpx(2);
  height: rpx(18);
  margin: 0 rpx(18);
  display: inline-block;
  background: #C8C8C8;
}



