@import "../../../../Components/SCSS/mixins";

.root{
  padding: rpx(16 * 2) rpx(16 * 2) 0 rpx(16 * 2);
  background-color: #F6F6F6;

  :global{
    .za-input--number .za-input__virtual-input{
      left: auto;
    }
    .za-input__content{
      width: rpx(420);
    }
  }

  :global{
    .za-input input{
      text-align: right;
    }

    .za-input textarea{
      margin-top: rpx(16);
    }

    .za-date-select__input{
      padding: 0 rpx(20 * 2) 0 rpx(20 * 2);
      text-align: right;

      &:after{
        top: rpx(6 * 2);
      }
    }

    .za-date-select__value{
      display: inline-block;
      text-align: left;
    }
  }
}

.cell{
  &:last-child{
    border: none;
  }
}

.name{
  line-height: rpx(22 * 2);
  font-size: rpx(28);
  color:#333333;;

  span{
    vertical-align: middle;
  }
}

.large{
  flex: 2 0 auto!important;
}

.textArea{
  display: block!important;
}

.required{
  padding-right: 15px;
  background: url("../img/red.png") right center no-repeat;
  background-size: rpx(8 * 2);
}

.dashed{
  border-bottom: 1px dashed #888888!important;
}

.button{
  margin: rpx(15 * 2) 0 0 rpx(128);
  @include beautiful-button(215 * 2, 40 * 2);//按钮特殊传对应px的数字
  font-size: rpx(16 * 2);
  color: #ffffff;
}
