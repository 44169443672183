@import "../../../../Components/SCSS/mixins";

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
}

.show {
  display: block;
}

.hidden {
  display: none;
}

.companionDetailDiv {
  position: fixed;
  bottom: 0;
  width: 100%;
  //height: rpx(1071);
  background: #FFFFFF;
  border-radius: rpx(40) rpx(40) 0 0;
  transition: all .3s;

}

.transform1400 {
  transform: translateY(rpx(1400));
}

.detailDiv {
  position: relative;
}

.close {
  position: absolute;
  top: rpx(42);
  right: rpx(42);
  width: rpx(26);
  height: rpx(24);
}

.headImg {
  position: absolute;
  top: rpx(-144);
  left: 50%;
  transform: translateX(-50%);
  background: url("./img/comHeadBg.png")no-repeat center center;
  background-size: rpx(288) rpx(250);
  padding: rpx(26) rpx(46);

  img {
    width: rpx(196);
    height: rpx(196);
    border-radius: 50%;
  }
}

.content {
  //margin-top: rpx(82);
  padding: rpx(82) rpx(64) rpx(40) rpx(64);
}

.contentRow {
  position: relative;
  display: flex;
  padding: rpx(30) 0;
  line-height: rpx(44);
  border-bottom: 1px solid #EFEFEF;

  &:first-child {
    border-bottom: 1px dashed #555555;
  }

  &:last-child {
    border-bottom: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: rpx(38);
    left: rpx(-10);
    width: rpx(6);
    height: rpx(26);
    border-radius: rpx(3);
    background: linear-gradient(to Bottom, #FF8734, #D5000B);
  }
}

.leftLabel {
  padding-left: rpx(12);
  width: 40%;
  font-size: rpx(32);
  color: #333333;
  line-height: rpx(44);
  font-weight: 700;
}

.rightFont {
  width: 60%;
  text-align: right;
  font-size: rpx(28);
  color: #666666;
  line-height: rpx(48);
}
