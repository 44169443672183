@import "src/Components/SCSS/mixins";

.content {
  display: flex;
  justify-content: left;
}

.message {
  width: rpx(186);
  height: rpx(186);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: rpx(32);
}

.materialPic {
  position: relative;
  width: rpx(186);
  height: rpx(186);
  border-radius: rpx(20);
  background: url("./img/camera.png") no-repeat center center #EFEFEF;
  background-size: rpx(60);
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: rpx(186);
    height: rpx(186);
  }
}

.showImg{
  width: 100%;
  height: 100%;
  z-index: 9;
  border: none;

}

.deleteImg{
  position: absolute;
  top: rpx(10);
  right: rpx(10);
  width: rpx(40);
  height: rpx(40);
  opacity: 0.5;
  z-index: 99;
}
