@import "../../../../Components/SCSS/mixins";

.root{
  padding-top: rpx(67 * 2);
  padding-bottom: rpx(20 * 2);
}

.list{
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.item{
  position: relative;
  flex: 0 0 rpx(147 * 2);
  margin-bottom: rpx(10 * 2);
}



.addBtn{
  text-align: center;

  &Bg{
    width: 100%;
    height: 100%;
  }
}

.content{
  position: absolute;
  bottom: rpx(11 * 2);
  left: 0;
  width: 100%;
  color: #959595;
  font-size: rpx(14 * 2);
}

.notice{
  position: absolute;
  top: 0;
  left: 0;
  width: rpx(343 * 2);
  height: rpx(29 * 2);
}

.noticeFont{
  color: #FE8141;
  font-size: rpx(24);
  line-height: rpx(58);
  width: 100%;
  height: rpx(58);
  background-color: #FFF5DA;
  padding-left: rpx(32);
}

.person{
  margin: 0 auto;
  height: rpx(94 * 2);
  text-align: center;
  background: url("./img/bg.png") left bottom no-repeat;
  background-size: 100%;
}

.info{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: rpx(12);
  height: rpx(93 * 2);
  border: rpx(2) solid #E7E7E7;
  border-radius: rpx(4 * 2);
}

.img{
  width: rpx(42 * 2);
  height: rpx(58 * 2);
}

.wrapper{
  position: absolute;
  top: rpx(-22 * 2);
  width: 100%;
  text-align: center;
}

.call{
  text-align: center;

  span{
    display: inline-block;
    max-width: rpx(300);
    padding: 0 rpx(8 * 2) 0 rpx(8 * 2);
    line-height: rpx(19 * 2);
    color: #FE8141;
    font-size: rpx(11 * 2);
    background-color: #FFF5DA;
    border-radius: rpx(4 * 2);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.name{
  padding: 0 rpx(5 * 2);
  color: #333333;
  font-size: rpx(28);
  text-align: center;
  line-height: rpx(22 * 2);
}

.del{
  position: absolute;
  top: rpx(5 * 2);
  right: rpx(10 * 2);
  width: rpx(20 * 2);
  height: rpx(20 * 2);
}

.prefect{
  position: absolute;
  top: 0;
  left: 0;
  width: rpx(108);
  height: rpx(42);
  line-height: rpx(42);
  background: #ffffff url("./img/不完善标签 2@2x.png") top left no-repeat;
  background-size: 100%;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
}
