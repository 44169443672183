@import "src/Components/SCSS/mixins";

.root{
  :global{
    .za-input input{
      text-align: right;
    }
    .za-input--number .za-input__virtual-input{
      left: auto;
    }
  }
}

.card{
  position: relative;
  padding-top: rpx(30 * 2);
  width: 100%;
  box-shadow: 0px -4px 11px -7px rgb(0 0 0 / 60%);
}

.rectangleCard{
  padding-top: rpx(16 * 2);
  width: 100%;
  border-radius: 0;
  box-shadow: none;
  margin: 0 auto 0 auto;
}

.cell{
  &:last-child{
    border: none;
  }
}

.title{
  margin-bottom: rpx(10 * 2);
  font-size: rpx(14 * 2);

  span{
    vertical-align: middle;
  }

  &:before{
    content: '';
    display: inline-block;
    margin-right: rpx(6 * 2);
    width: rpx(4 * 2);
    height:  rpx(13 * 2);
    background-image: linear-gradient(to top, #D5000B, #FF8734);
    border-radius: rpx(2 * 2);
    vertical-align: middle;
  }
}

.name{
  line-height: rpx(22 * 2);
  font-size: rpx(28);
  color:#333333;;

  span{
    vertical-align: middle;
  }
}

.memberWrapper{
  padding: rpx(20 * 2) rpx(16 * 2) rpx(12 * 2) rpx(16 * 2);
}

.formContent {
  position: relative;
  border-left: rpx(2) dashed #FF3E16;
  padding-left: rpx(30);

  &:before {
    content: "";
    position: absolute;
    left: rpx(-6);
    top: 0;
    width: rpx(10);
    height: rpx(50);
    background: #FFFFFF;
  }
}

.cell{
  position: relative;
  &:last-child{
    border: none;

    &:after{
      content: "";
      position: absolute;
      left: rpx(-38);
      top: rpx(58);
      width: rpx(10);
      height: 100%;
      background: #FFFFFF;
    }
  }

  &:before {
    content: "";
    left: rpx(-38);
    top: rpx(44);
    width: rpx(14);
    height: rpx(14);
    position: absolute;
    background: url("./img/squareIcon.png")no-repeat center center;
    background-size: rpx(14) rpx(14);
  }
}

.description{
  position: absolute;
  bottom: 0;
  font-size: rpx(12 * 2);
  color: #FF0000;
}

.required{
  padding-right: 15px;
  background: url("./img/red.png") right center no-repeat;
  background-size: rpx(8 * 2);
}

.large{
  flex: 2 0 auto!important;
}

.largeName {
  width: 70%;
}

.fontStyle{
  font-size: rpx(28);
}

.right {
  flex: 0 0 rpx(100 * 2);
}

.textArea{
  display: block!important;
}

.tags{
  display: flex;
  justify-content: center;
}

.tag{
  width: rpx(126 * 2);
  line-height: rpx(40 * 2);
  color: #FF3E16;
  font-size: rpx(16 * 2);
  text-align: center;
  border-radius: rpx(10 * 2);
  background-image: none;
  border: rpx(1 * 2) solid ;

  &:first-child{
    margin-right: rpx(24 * 2);
  }
}

.current{
  background-image: linear-gradient(to right, #D5000B, #FF8734);
  color: #ffffff;
}

.subHeading{
  margin: rpx(16 * 2) 0 rpx(18 * 2) 0;
  color: #E13F3F;
  font-size: rpx(12 * 2);
  text-align: center;

  span{
    vertical-align: middle;
  }

  &:before, &:after{
    content: '';
    display: inline-block;
    margin: 0 rpx(21 * 2);
    width: rpx(48);
    height: rpx(28);
    background: url("./img/三角装饰-右 2@2x.png") left center no-repeat;
    background-size: 100%;
    vertical-align: middle;
  }

  &:before{
    transform: rotateY(180deg);
  }
}

.subTags{
  display: flex;
  margin: 0 rpx(-6 * 2);
  justify-content: space-around;
}

.subTag{
  padding: 0 rpx(14 * 2) rpx(10) rpx(14 * 2);
  border-radius: rpx(10 * 2);
  color: #AAAAAA;
  background-color: #EDEDED;
  text-align: center;
  font-size: rpx(14 * 2);
  line-height: rpx(40 * 2);
}

.selected{
  color: #ffffff;
  background-image: linear-gradient(to right, #D5000B, #FF8734);
}

.cardAsset{
  position: relative;
  top: rpx(-6 * 2);
  width: 100%;
  box-shadow: 0px -4px 11px -7px rgb(0 0 0 / 60%);

  &Wrapper{
    margin: 0 rpx(-16 * 2);
  }
}

.buttonDiv {
  background: #FFFFFF;
  padding: rpx(15 * 2) 0 rpx(42 * 2) 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button {
  @include beautiful-button(286, 84);
  font-size: rpx(32);
}

.reRoot {
  padding: 0;
}

.reButton {
  @include beautiful-button(286, 84);
  font-size: rpx(32);
}
