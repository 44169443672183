@import "../../../../Components/SCSS/mixins";

.companionDiv {
}

.comTip {
  width: 100%;
  text-align: center;
  font-size: rpx(28);
  color: #555555;
  line-height: rpx(40);
  span {
    color: #E13F3F;
  }
}

.companionList {
  margin-top: rpx(36);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.companionRow {
  position: relative;
  text-align: center;
  width: rpx(326);
  min-height: rpx(240);
  border-radius: rpx(20);
  background: #FFFFFF;
  margin-bottom: rpx(40);

  &:before {
    content: "";
    width: rpx(8);
    height: rpx(38);
    border-radius: rpx(20) 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, #FF8734, #D5000B);
  }
}

.topIcon {
  position: absolute;
  width: rpx(68);
  height: rpx(56);
  top: rpx(-8);
  right: rpx(30);
  font-size: rpx(28);
  padding-top: rpx(8);
  line-height: rpx(32);
  color: #FFFFFF;
}

.man {
  background: url("./img/manIcon.png")no-repeat center center;
  background-size: rpx(68) rpx(56);
}

.woman {
  background: url("./img/womanIcon.png")no-repeat center center;
  background-size: rpx(68) rpx(56);
}

.comHeadImg {
  margin: rpx(20) 0 rpx(12) 0;
  width: rpx(100);
  height: rpx(100);
  border-radius: 50%;
}

.name {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: rpx(32);
  line-height: rpx(44);
  color: #000000;
}

.area {
  padding: 0 rpx(20);
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; //需要控制的文本行数
  overflow: hidden;
  font-size: rpx(24);
  line-height: rpx(34);
  color: #666666;

}
