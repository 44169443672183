@import "src/Components/SCSS/mixins";

.container {
  position: relative;
  width: 100%;
  padding: rpx(60) rpx(32) 0 rpx(32);
  border-radius: rpx(40) rpx(40) 0 0;
  box-shadow: 0 rpx(-6) rpx(20) 0 rgba(0, 0, 0, 0.06);
  min-height: rpx(200);
  background: #FFFFFF;
}

.title {
  position: relative;
  padding-left: rpx(24);
  line-height: rpx(40);
  color: #333333;
  font-size: rpx(28);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: rpx(8);
    width: rpx(8);
    height: rpx(24);
    border-radius: rpx(8);
    background: linear-gradient(to bottom, #FF8734, #D5000B);
  }
}

.addButton {
  float: right;
  width: rpx(138);
  height: rpx(56);
  border-radius: rpx(12);
  background: #FFF3DA;
  font-size: rpx(28);
  line-height: rpx(56);
  color: #FF8E25;
  text-align: center;
}

.deleteButton {
  position: relative;
  z-index: 2;
  width: rpx(138);
  height: rpx(56);
  border-radius: rpx(12);
  background: #D2D2D2;
  font-size: rpx(28);
  line-height: rpx(56);
  color: #FFFFFF;
  text-align: center;
}

.buttonDiv {
  background: #FFFFFF;
  padding: rpx(15 * 2) 0 rpx(42 * 2) 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button {
  @include beautiful-button(286, 84);
  font-size: rpx(32);
}

.reRoot {
  padding: 0;
}

.reButton {
  @include beautiful-button(286, 84);
  font-size: rpx(32);
}
