@import "src/Components/SCSS/mixins";

.root{
  margin: 0 auto;
  width: rpx(520);
}

.img{
  width: 100%;
}

.msg{
  text-align: center;
  font-size: rpx(24);
  color: #B8B8B8;
}

