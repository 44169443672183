@import "../../../../Components/SCSS/mixins";

.root {
  height: 100vh;
  background-color: #F6F6F6;
}

.container {
  height: 100%;
  padding: rpx(32);
  background: url("./img/topBg.png") no-repeat center top;
  background-size: 100% rpx(426);
}

.title {
  margin: rpx(66) 0 rpx(160) rpx(46);
  line-height: rpx(56);
  font-size: rpx(40);
  color: #FFF;
}

.card {
  width: rpx(684);
  min-height: rpx(530);
  border-radius: rpx(20);
  background-color: #FFF;
  overflow: hidden;
}

.stuInfo {
  padding-top: rpx(6);
  min-height: rpx(530);
}

.btn {
  margin-top: rpx(60);
  margin-left: rpx(128);
}

.button {
  @include beautiful-button(430, 84);
  font-size: rpx(32);
}

.graybutton {
  width: rpx(430);
  height: rpx(84);
  border-radius: rpx(42);
  background: rgb(197, 197, 197);
  color: white;
  border: none;
}

//弹窗
.tip {
  max-width: rpx(448);
  text-align: center;
  color: #222222;
  font-size: rpx(32);
  line-height: rpx(44);

  span {
    display: block;
  }
}

.toastContent {
  margin-top: rpx(0);
  max-width: rpx(488);
  display: flex;
  flex-direction: column;
  align-items: center;
}

//未填报
.notFillIn {
  height: rpx(450);
}

.notFillInBtn {
  margin-top: rpx(42);
  @include beautiful-button(386, 84);
  font-size: rpx(32);
}

//未进入签到范围
.notRange {
  height: rpx(382);
}

.notRangeBtn {
  margin-top: rpx(48);
  @include beautiful-button(246, 84);
  font-size: rpx(32);
}

//未开启
.notOpen {
  height: rpx(420);
}

.notOpenBtn {
  margin-top: rpx(52);
  @include beautiful-button(366, 84);
  font-size: rpx(32);
}

