@import "../../Components/SCSS/mixins";

.root {
  width: 100%;
}

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
}

.contDiv {
  width: rpx(594);
  padding: rpx(16);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF;
  border-radius: rpx(40);
}

.toastCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: rpx(32);
  box-shadow: 0 0 rpx(6) 0 rgba(255, 153, 108, 0.3);
}

.toastTop {
  width: rpx(362) !important;
  height: rpx(90) !important;
  position: absolute;
  top: rpx(-26);
  left: rpx(120);
}

.title {
  line-height: rpx(56);
  font-size: rpx(40);
  position: absolute;
  top: rpx(-10);
  left: rpx(220);
  color: #FFFFFF;
  text-shadow: 5px 5px 5px #B41800 ;
}

.arr {
  width: rpx(48);
  height: rpx(30);
  position: absolute;
  top: rpx(128);
  left: rpx(50);
}

.leftArr {
  width: 100%;
}

.rightArr {
  width: 100%;
  transform: rotateY(180deg);
  position: absolute;
  left: rpx(446);
}

.tip {
  margin-top: rpx(120);
}

