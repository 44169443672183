@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.noticeDiv {
  padding: rpx(44) rpx(32) rpx(20) rpx(32);
  height: rpx(318);
  border-radius: rpx(20);
  background: #ffffff;
}

.headerDiv {
  overflow: hidden;
  .headerTitle {
    position: relative;
    float: left;
    font-size: rpx(32);
    color: #111111;
    line-height: rpx(44);
    font-weight: 700;

    img {
      width: rpx(34);
      height: rpx(34);
      margin-right: rpx(14);
    }

    .headFont {
      position: absolute;
      top: 0;
      left: rpx(50);
      z-index: 1;
      width: rpx(300);
      text-align: left;
    }

    .row {
      position: absolute;
      z-index: 0;
      left: rpx(50);
      bottom: 0;
      width: rpx(140);
      height: rpx(8);
      border-radius: rpx(8);
      background: linear-gradient(to right, #FF3E00, #ffffff);
    }
  }

  .headerMore {
    float: right;
    font-size: rpx(24);
    line-height: rpx(36);
    color: #96999F;

    span {
      display: inline-block;
      vertical-align: middle;
    }

    img {
      margin-left: rpx(6);
      margin-top: rpx(2);
      vertical-align: middle;
      height: rpx(22);
      width: rpx(14);
    }
  }
}

.noticeList {
  margin-top: rpx(38);
  padding: 0 rpx(48);
}

.noticeRow {
  margin-bottom: rpx(16);
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  color: #333333;
  font-size: rpx(28);
  line-height: rpx(40);

  &:last-child {
    margin-bottom: 0;
  }

  span {
    color: #999999;
  }
}

.empty {
  position: relative;
  text-align: center;
  padding-top: rpx(100);
  height: rpx(152);
  line-height: rpx(152);
  font-size: rpx(28);
  color: #999999;

  img {
    position: absolute;
    top: rpx(-40);
    left: 50%;
    transform: translate(-50%);
    width: rpx(240);
    height: rpx(234);
  }
}
