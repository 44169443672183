@import "../SCSS/mixins";

.root{
  :global{
    .za-select__input{
      padding: 0 rpx(20 * 2) 0 rpx(20 * 2);
      text-align: right;

      &:after{
        top: rpx(6 * 2);
      }
    }

    .za-select__value{
      display: inline-block;
      text-align: left;
    }
  }
}
