@import "src/Components/SCSS/mixins";


.root {
  width: 100%;
  padding: rpx(60) rpx(60) 0 rpx(32);
  background: #FFF;
  position: relative;
  border-top-left-radius: rpx(40);
  border-top-right-radius: rpx(40);
  box-shadow: 0 rpx(-6) rpx(20) 0 rgba(0, 0, 0, 0.06);
  min-height: rpx(1055);

  :global {
    .za-checkbox__widget {
      border-color: #FF3E16 !important;
    }

    .za-checkbox--checked .za-checkbox__inner {
      background: linear-gradient(#FF8734, #D5000B);
      border-color: #FF3E16 !important;
    }

    .za-checkbox:not(.za-checkbox--disabled):hover .za-checkbox__inner {
      border-color: #CCCCCC !important;
    }

    .za-button, .za-button:hover{
      border-color: #D8D8D8 !important;
      background: #D8D8D8;
      color: #fff;
    }
  }
}

.title {
  position: relative;
  line-height: rpx(40);
  font-size: rpx(28);
  color: #333;
  display: flex;
  align-items: center;
}

.line {
  width: rpx(8);
  height: rpx(24);
  margin-right: rpx(10);
  display: inline-block;
  border-radius: rpx(4);
  background-image: linear-gradient(#FF8734, #D5000B);
}

.preview {
  width: rpx(392);
  height: rpx(84);
  margin: rpx(134) auto;
  background: url("./img/buttonBg.png") no-repeat center;
  background-size: 100%;
  line-height: rpx(84);
  text-align: center;
  color: #FF3E16;
  font-size: rpx(32);
  font-weight: bold;
}

.tip {
  color: #FF0000;
  font-size: rpx(24);
  line-height: rpx(34);
  font-weight: bold;
  margin-left: rpx(34);
}

.btn {
  margin-top: rpx(30);
  margin-left: rpx(150);
  @include beautiful-button(366, 84);
  font-size: rpx(32);
}
.preyBtn{
  width: rpx(366);
  height: rpx(84);
  margin-top: rpx(30);
  margin-left: rpx(150);
  border-radius: rpx(42);
  background: #D8D8D8;
  color: #FFF;
  font-size: rpx(32);
}

.buttonDiv {
  position: absolute;
  right: rpx(-20);
}

.button {
  @include beautiful-button(134, 56);
  font-size: rpx(28);
}
