
@mixin reset-button{
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}


@mixin beautiful-button($width, $height, $start-color: #d5000a, $end-color: #fb7c30) {
  position: relative;
  @include reset-button;
  width: rpx($width);
  height: rpx($height);
  border-radius: rpx($height / 2);
  overflow: visible;
  z-index: 0;

  &:before{
    content: "";
    position: absolute;
    width: rpx($width);
    height: rpx($height);
    left: 0;
    top: 0;
    border-radius: rpx($height / 2);
    @include gradient-horizontal($start-color, $end-color);
    z-index: -1;
  }

  &:after{
    display: block;
    position: absolute;
    content: "";
    width: 80%;
    height: 80%;
    top: 0;
    left: 50%;
    border-radius: rpx($height / 2);
    transform: translate(-50%, 40%);
    @include gradient-horizontal($start-color, $end-color);
    filter: blur(rpx($height / 6)) brightness(80%) opacity(.8);
    z-index: -2;
  }
}

@mixin already-button($width, $height, $start-color: #d5000a, $end-color: #fb7c30) {
  position: relative;
  display: block;
  border: 0;
  transition: color 0.3s;
  width: rpx($width);
  height: rpx($height);
  border-radius: rpx($height / 2);
  z-index: 0;
  @include gradient-horizontal($start-color, $end-color);
  &:hover {
    color: #E13F3F;
  }

  &:active {
    color: #FFFFFF;
  }

  &:after{
    position: absolute;
    content: "";
    top: rpx(2);
    left: rpx(2);
    right: rpx(2);
    bottom: rpx(2);
    border-radius: rpx($height / 2);
    background: #F6F6F6;
    z-index: -1;
  }
}
