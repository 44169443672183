@import "src/Components/SCSS/mixins";

.root{

}

.wrapper{
  padding: 0 rpx(50) 0 rpx(50);
}

.tags{
  position: relative;
  top: rpx(12 * 2);
  display: flex;
  margin-top: rpx(30 * 2);
  padding: 0 rpx(15 * 2) 0 rpx(15 * 2);
  justify-content: space-between;
}

.tag{
  padding: rpx(13 * 2) rpx(13 * 2) 0 rpx(13 * 2);
  width: rpx(55 * 2);
  height: rpx(69 * 2);
  line-height: rpx(20 * 2);
  font-size: rpx(12 * 2);
  color: #AAAAAA;
  background-color: #ffffff;
  border-radius: rpx(10 * 2);
  text-align: center;
}

.current{
  position: relative;
  background-image: linear-gradient(to right top, #D5000B, #FF8734);
  color: #ffffff;
  z-index: 9;

  &:after{
    position: absolute;
    right: 0;
    bottom: rpx(10 * 2);
    content: '';
    width: rpx(48 * 2);
    height: rpx(3 * 2);
    background-image: linear-gradient(to right,rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
  }
}

.large{
  padding-left: rpx(6 * 2);
  padding-right: rpx(6 * 2);
}

.title{
  margin-bottom: rpx(10 * 2);
  padding-left: rpx(6 * 2);
  font-size: rpx(14 * 2);
  color: #333333;

  &:before{
    content: '';
    display: inline-block;
    margin-right: rpx(6 * 2);
    width: rpx(4 * 2);
    height:  rpx(13 * 2);
    background-color: #CA0C15;;
    border-radius: rpx(2 * 2);
    vertical-align: middle;
  }
}

//弹窗内容
.popContent {
  padding: rpx(92) rpx(34) rpx(0) rpx(34);
}

.popTitle {
  padding: rpx(82) 0 rpx(42) 0;
  text-align: center;
  font-size: rpx(32);
  color: #222222;
  line-height: rpx(44);
  font-weight: 700;
  background: url("./img/titleIcon.png")no-repeat rpx(148) rpx(62);
  background-size: rpx(82) rpx(66);
}

.detailRow {
  position: relative;
  font-size: rpx(28);
  color: #222222;
  line-height: rpx(40);
  padding-left: rpx(24);
  margin-bottom: rpx(50);

  &:last-child {
    margin-bottom: 0;
  }

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: rpx(8);
    width: rpx(16);
    height: rpx(22);
    background: url("./img/rowIcon.png")no-repeat center center;
    background-size: rpx(16) rpx(22);
  }
}

.popButtonDiv {
  padding: rpx(40) 0 rpx(60) 0;
  text-align: center;
}

.popButton {
  @include beautiful-button(366, 84);//按钮特殊传对应px的数字
  font-size: rpx(32);
}

.record{
  top: rpx(10)
}
