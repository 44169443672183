@import "src/Components/SCSS/mixins";


.root{
  @include text-center;
  width: 100%;
  background: #F6F6F6;
}

.indexDiv {
  width: 100%;
  height: 100%;
  padding: rpx(20) rpx(32) rpx(106) rpx(32);
  background: url("./img/topBg.png")no-repeat center top;
  background-size: 100% rpx(426);
}

.button{
  @include beautiful-button(160, 56);//按钮特殊传对应px的数字
  font-size: rpx(24);
}

.logout{
  margin-top: rpx(20 * 2);
  width: rpx(132 * 2);
  line-height: rpx(36 * 2);
  border-radius: rpx(36 * 2);
  font-size: rpx(14 * 2);
  color: #999999;
  border-color: #DCDCDC;
  background-color: #F6F6F6;
}
