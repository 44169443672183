@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.buttonListDiv {
  margin-top: rpx(30);
  padding: rpx(44) rpx(32);
  height: rpx(500);
  border-radius: rpx(20);
  background: #ffffff;
}

.headerDiv {
  overflow: hidden;
  .headerTitle {
    position: relative;
    float: left;
    font-size: rpx(32);
    color: #111111;
    line-height: rpx(44);
    font-weight: 700;

    img {
      width: rpx(34);
      height: rpx(34);
      margin-right: rpx(14);
    }

    .headFont {
      position: absolute;
      top: 0;
      left: rpx(50);
      z-index: 1;
      width: rpx(300);
      text-align: left;
    }

    .row {
      position: absolute;
      z-index: 0;
      left: rpx(50);
      bottom: 0;
      width: rpx(140);
      height: rpx(8);
      border-radius: rpx(8);
      background: linear-gradient(to right, #FF3E00, #ffffff);
    }
  }
}

.buttonRow {
  border-bottom: 1px solid #E4E7EE;
  padding: rpx(40) 0;
  display: flex;
  flex-wrap: nowrap;

  &:last-child {
    border-bottom: 0;
  }
}

.buttonDiv {
  width: 20%;
  border-right: rpx(1) solid #E4E7EE;
  text-align: center;

  &:last-child {
    border-right: 0;
  }

  img {
    width: rpx(100);
    height: rpx(84);
  }

  .buttonFont {
    font-size: rpx(24);
    color: #7787A5;
    line-height: rpx(34);
  }
}

