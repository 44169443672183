@import "src/Components/SCSS/mixins";

.root{
  margin: 0 auto rpx(15 * 2) auto;
  padding: 0 rpx(16 * 2);
  width: rpx(343 * 2);
  border-radius: rpx(10 * 2);
  background-color: #ffffff;
  overflow: hidden;
}
