@import "src/Components/SCSS/mixins";


.root{
  @include text-center;
  width: 100%;
  background: #F6F6F6;
}

.admissionLetterDiv {
  position: relative;
  width: 100%;
  height: rpx(1206);
  background: url("./img/letterBg.png")no-repeat center top;
  background-size: 100% rpx(1206);
}

.dufeCode {
  position: absolute;
  top: rpx(354);
  right: rpx(108);
  font-size: rpx(24);
  color: #222222;
  font-weight: 700;
}

.info {
  position: absolute;
  top: rpx(470);
  left: rpx(108);
  text-align: left;
}

.fontRow {
  margin-bottom: rpx(18);
}

.rowLabel {
  color: #222222;
  font-size: rpx(24);
}

.rowFont {
  color: #222222;
  font-size: rpx(24);
  font-weight: 700;
}

.picImg {
  position: absolute;
  top: rpx(470);
  right: rpx(108);
  img {
    width: rpx(140);
    height: rpx(188);
  }
}

.detail {
  position: absolute;
  width: rpx(534);
  top: rpx(724);
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  text-indent: rpx(48);
  font-size: rpx(24);
  color: #555555;
  line-height: rpx(44);
}

.fontRed {
  color: #c20f14;
  text-decoration: underline;
}

.signature {
  position: absolute;
  top: rpx(928);
  left: rpx(98);

  img {
    vertical-align: middle;
    width: rpx(88);
    height: rpx(102);
  }
}

.dateDiv {
  position: absolute;
  top: rpx(964);
  right: rpx(90);
  text-align: right;

  .name {
    font-size: rpx(28);
    color: #222222;
    line-height: rpx(40);
  }

  .date {
    font-size: rpx(24);
    color: #444444;
    line-height: rpx(34);
  }
}
