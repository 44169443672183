@import "src/Components/SCSS/mixins";

.pdfDiv {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.pageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  bottom: rpx(10);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  .flex1 {
    width: 33%;
    text-align: center;
  }
}

.marginBottom {
  margin-bottom: rpx(40);
}

.button {
  @include beautiful-button(200, 64);
  font-size: rpx(28);
  margin-bottom: rpx(40);
}
