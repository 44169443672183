@import "src/Components/SCSS/mixins";

.container {
  position: relative;
  width: 100%;
  border-radius: rpx(40) rpx(40) 0 0;
  box-shadow: 0 rpx(-6) rpx(20) 0 rgba(0, 0, 0, 0.06);
  min-height: rpx(200);
  background: #FFFFFF;

  :global{
    .za-input input{
      text-align: right;
    }
    .za-date-select__input {
      padding: 0 rpx(30) 0 0;
    }

    .za-date-select--arrow .za-date-select__input:after {
      top: rpx(18);
      transform: rotate(315deg);
    }

    .za-select__input {
      padding: 0 rpx(30) 0 0;

    }

    .za-select--arrow .za-select__input:after {
      top: rpx(18);
      transform: rotate(315deg);
    }

  }

}

//表单样式start
.notice{
  color: #FF0000;
  font-size: rpx(12 * 2);
  line-height: rpx(49 * 2);

  span{
    padding-left: rpx(6 * 2);
    vertical-align: middle;
  }

  img{
    margin-left: rpx(7 * 2);
    width: rpx(6 * 2);
    vertical-align: middle;
  }
}

.large{
  flex: 2 0 auto!important;
}

.largeName{
  width: 40%;
}

.cell{
  position: relative;
  &:last-child{
    border: none;
  }

  &:before {
    content: "";
    left: rpx(-38);
    top: rpx(44);
    width: rpx(14);
    height: rpx(14);
    position: absolute;
    background: url("./img/squareIcon.png")no-repeat center center;
    background-size: rpx(14) rpx(14);
  }
}

.refCell {
  &:after {
    content: "";
    position: absolute;
    left: rpx(-38);
    top: rpx(58);
    width: rpx(10);
    height: rpx(50);
    background: #FFFFFF;
  }
}

.refLargeCell {
  &:after {
    content: "";
    position: absolute;
    left: rpx(-38);
    top: rpx(58);
    width: rpx(10);
    height: rpx(90);
    background: #FFFFFF;
  }
}

.dashed{
  border-bottom: 1px dashed #888888!important;
}

.row{
  position: relative;
  width: 100%;
  text-align: right;
}

.name{
  line-height: rpx(22 * 2);
  font-size: rpx(28);
  color:#333333;;

  span{
    vertical-align: middle;
  }
}

.desName {
  position: relative;
}

.desIcon {
  position: absolute;
  left: rpx(270);
  top: rpx(15);
  width: rpx(32);
  height: rpx(32);
}

.input{
  text-align: right;
}

.required{
  padding-right: 15px;
  background: url("img/red.png") right center no-repeat;
  background-size: rpx(8 * 2);
}

.formContent {
  position: relative;
  border-left: rpx(2) dashed #FF3E16;
  padding-left: rpx(30);

  &:before {
    content: "";
    position: absolute;
    left: rpx(-6);
    top: 0;
    width: rpx(10);
    height: rpx(50);
    background: #FFFFFF;
  }
}

.textArea{
  display: block!important;
}

//表单样式end

.topTip {
  padding-left: rpx(14);
  width: 100%;
  height: rpx(68);
  line-height: rpx(68);
  border-radius: rpx(20) rpx(20) 0 0;
  font-size: rpx(24);
  color: #FE8141;
  background: #FFF5DA;
}

.title {
  position: relative;
  padding: rpx(60) 0 rpx(30) rpx(24);
  line-height: rpx(40);
  color: #333333;
  font-size: rpx(28);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: rpx(68);
    width: rpx(8);
    height: rpx(24);
    border-radius: rpx(8);
    background: linear-gradient(to bottom, #FF8734, #D5000B);
  }
}

.rowLine {
  width: 100%;
  height: rpx(20);
  background: #F6F6F6;
}
