@import "../../../Components/SCSS/mixins";

.root {
  height: 100vh;
  background-color: #F6F6F6;
}

.container {
  height: 100%;
  padding: rpx(350) rpx(32) rpx(32) rpx(32);
  background: url("./img/topBg2.png") no-repeat center top;
  background-size: 100% rpx(540);
}

.title {
  margin: rpx(66) 0 rpx(160) rpx(46);
  line-height: rpx(56);
  font-size: rpx(40);
  color: #FFF;
}

.card {
  width: rpx(684);
  border-radius: rpx(20);
  background-color: #FFF;
  overflow: hidden;
}

.stuInfo {
  padding-top: rpx(6);
}

.state {
  height: rpx(56);
  line-height: rpx(56);
  display: flex;
  justify-content: space-between;
  color: #FF800B;
  font-size: rpx(28);

  div {
    width: rpx(344);
    text-align: center;
    background-color: #FFE9D6;
  }
}

.active {
  color: #F06B5A;
  background-color: #FFD8CE !important;
}

.line {
  position: absolute;
  left: 50%;
  color: #000;
  opacity: 0.12;
  font-size: rpx(24);
}

.lineHidden {
  display: none;
}

.btn {
  margin-top: rpx(60);
  //margin-left: rpx(128);
}

.button {
  margin: 0 0 rpx(40) rpx(128);
  @include beautiful-button(430, 84);
  font-size: rpx(32);
}

//空心按鈕
.alreadyButton {
  margin: 0 auto;
  color: #E13F3F;
  @include already-button(430, 85);
  font-size: rpx(32);
  background-color: #F6F6F6;
}
