@import "../../../../../Components/SCSS/mixins";

.root {
  height: 100%;
}

.tip {
  background: #FFF5DA;
  color: #FE8141;
  font-size: rpx(24);
  line-height: rpx(34);
  padding: rpx(16) rpx(32);
  display: flex;
  align-items: center;
}

.iconPic {
  width: rpx(26);
  height: rpx(26);
  margin-right: rpx(12);
}

.xing {
  font-size: rpx(24);
  margin-right: rpx(12);
}

.tipSuccess {
  padding: rpx(16) rpx(20);
  color: #60BA00;
  background: #E1F1D1;
}

.tipError {
  padding: rpx(16) rpx(20);
  background: #FFE6DA;
  color: #E13F3F;
}
