@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.admissionDiv {
  margin-top: rpx(22);
  margin-bottom: rpx(30);
  width: 100%;
  height: rpx(232);
  border-radius: rpx(20);
  box-shadow: 0 rpx(6) rpx(16) 0 rgba(208, 175, 169, 0.2);
  background: #ffffff;
  display: flex;
  flex-wrap: nowrap;
}

.button {
  width: 20%;
  overflow: hidden;
  padding-top: 22px;
}

.buttonDiv {
  position: relative;
  margin: 0 auto;
  text-align: center;

  .buttonBefore {
    position: absolute;
    top: rpx(-20);
    right: rpx(2);
    height: rpx(34);
    border-radius: rpx(17);
    padding: 0 rpx(8);
    background: #E6000B;
    font-size: rpx(20);
    color: #ffffff;
    line-height: rpx(36);
  }

  .buttonIcon {
    width: rpx(82);
    height: rpx(80);
  }

  .font {
    color: #333333;
    font-size: rpx(24);
    line-height: rpx(34);
  }

  .detail {
    line-height: rpx(28);
    font-size: rpx(20);
    transform: translate(0.8);
    color: #999999;

  }
}
