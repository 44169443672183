@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.guideDiv {
  margin-top: rpx(30);
  padding: rpx(40) rpx(20) rpx(20) rpx(36);
  height: rpx(266);
  border-radius: rpx(20);
  background: #ffffff;
}

.guideContent {
  text-align: left;
  width: 100%;
  height: 100%;
  background: url("./img/guideBg.png")no-repeat right bottom;
  background-size: rpx(360) rpx(186);
}

.guideTitle {
  line-height: rpx(50);
  color: #5F1E09;
  font-size: rpx(36);
  font-weight: 700;
}

.guideSubTitle {
  line-height: rpx(40);
  color: #5F1E09;
  font-size: rpx(28);
}

.button{
  margin-top: rpx(26);
  @include beautiful-button(160, 56);//按钮特殊传对应px的数字
  font-size: rpx(24);
}

