@import "src/Components/SCSS/mixins";

.root{
  padding: rpx(13 * 2) 0;
  display: flex;
  border-bottom: 1px solid #EFEFEF;
  font-size: rpx(14 * 2);
}

.left{
  flex: auto ;
  font-size: rpx(28) !important;
}

.right{
  flex: auto;
  align-self: center;
  text-align: right;
  font-size: rpx(26);

  :global{
    .za-input input, .za-select__value, .za-date-select__value, .za-input__virtual-input {
      color: #666666!important;
    }

    .za-date-select--disabled ,.za-select--disabled , .za-input--disabled{
      .za-input input, .za-select__value, .za-date-select__value, .za-input__virtual-input {
        color: #bcbcbc!important;
      }
    }
  }
}
