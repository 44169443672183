@import "src/Components/SCSS/mixins";

.root {
  height: 100vh;
  padding: rpx(32);
  background-color: #F6F6F6;
}

.container {
  margin-top: rpx(22);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nameAndNo {
  line-height: rpx(40);
  color: #555555;
  font-size: rpx(28);
}

.stuNo {
  margin-bottom: rpx(96);
  color: #999999;
}

.button {
  margin-top: rpx(30);
  @include beautiful-button(288, 84);
  font-size: rpx(32);
}

.live{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img{
  width: rpx(400);
  height: rpx(342);
  margin-top: rpx(102);
}
