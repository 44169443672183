@import "../../../../Components/SCSS/mixins";

.root{
  padding: rpx(15 * 2) 0 rpx(42 * 2) 0;
  text-align: center;
}

.button{
  @include beautiful-button(215 * 2, 40 * 2);//按钮特殊传对应px的数字
  font-size: rpx(16 * 2);
  color: #ffffff;
}
