@import "src/Components/SCSS/mixins";

.formRow {
  :global{
    .za-input input{
      text-align: right;
    }
    .za-date-select__input {
      padding: 0 rpx(30) 0 0;
    }

    .za-date-select--arrow .za-date-select__input:after {
      top: rpx(18);
      transform: rotate(315deg);
    }


    .za-select--arrow .za-select__input:after {
      top: rpx(18);
      transform: rotate(315deg);
    }

    .za-input__virtual-input {
      left: auto;
    }
    .za-input__placeholder {
      left: auto;
      right: 0;
    }
  }
}

.formContent {
  margin-top: rpx(34);
  position: relative;
  border-left: rpx(2) dashed #FF3E16;
  padding-left: rpx(30);

  &:before {
    content: "";
    position: absolute;
    left: rpx(-6);
    top: 0;
    width: rpx(10);
    height: rpx(44);
    background: #FFFFFF;
  }
}

.large{
  flex: 2 0 auto!important;
}

.largeName{
  width: 40%;
}

.name{
  line-height: rpx(22 * 2);
  font-size: rpx(16 * 2);
  color:#333333;;

  span{
    vertical-align: middle;
  }
}

.required {
  padding-right: 15px;
  background: url("img/red.png") right center no-repeat;
  background-size: rpx(8 * 2);
}

.input{
  text-align: right;
}

.cell{
  position: relative;
  &:last-child{
    border: none;

    &:after {
      content: "";
      position: absolute;
      left: rpx(-38);
      top: rpx(58);
      width: rpx(10);
      height: 100%;
      background: #FFFFFF;
    }
  }

  &:before {
    content: "";
    left: rpx(-38);
    top: rpx(44);
    width: rpx(14);
    height: rpx(14);
    position: absolute;
    background: url("./img/squareIcon.png")no-repeat center center;
    background-size: rpx(14) rpx(14);
  }


}

