@import "../../../Components/SCSS/mixins";


.root{
  width: 100%;
  min-height: 100vh;
  background: #F6F6F6;
}

.enrollmentDiv {
  padding: 0 rpx(40) rpx(40) rpx(40);

}

.tip {
  height: rpx(92);
  line-height: rpx(92);
  font-size: rpx(24);
  color: #FF0000;
  text-align: left;
}

.enrollRow {
  margin-bottom: rpx(44);
  overflow: hidden;
  position: relative;
  width: 100%;
  height: rpx(206);
  padding-left: rpx(48);
  padding-top: rpx(58);
  border-radius: rpx(20);
  box-shadow: 0 rpx(2) rpx(8) 0 rgba(128, 128, 128, 0.1);
  background: url("img/rowBg.png")no-repeat center center;
  background-size: cover;
}

.topType {
  position: absolute;
  width: rpx(84);
  height: rpx(36);
  line-height: rpx(36);
  left: 0;
  top: 0;
  font-size: rpx(20);
  text-align: center;
  color: #FFFFFF;
  border-radius: 0 0 rpx(20) 0;
  background: linear-gradient(to right, #D5000B, #FF8734);
}

.topTypeAlready {
  background: #DADADA;
}

.font {
  line-height: rpx(44);
  font-size: rpx(32);
  color: #151515;
  font-weight: 700;
}

.enFont {
  line-height: rpx(34);
  font-size: rpx(24);
  color: #BBBBBB;
}

.buttonDiv {
  position: absolute;
  right: rpx(40);
  top: rpx(64);
}

.button{
  @include beautiful-button(160, 60);//按钮特殊传对应px的数字
  font-size: rpx(28);
}

.alreadyButton {
  color: #E13F3F;
  @include already-button(160, 60);
  font-size: rpx(28);
}
