@import "../../../Components/SCSS/mixins";

.root {
  min-height: 100vh;
  padding: rpx(32);
  background-color: #F6F6F6;
}

.container {
  padding: rpx(40);
  border-radius: rpx(12);
  background-color: #FFF;
}

.title {
  line-height: rpx(50);
  margin-bottom: rpx(26);
  color: #333;
  font-size: rpx(36);
  font-weight: bold;
}

.time {
  line-height: rpx(36);
  padding-bottom: rpx(40);
  display: flex;
  color: #BBB;
  font-size: rpx(24);
  border-bottom: rpx(1) #D8D8D8 solid;
}

.content {
  line-height: rpx(44);
  color: #333;
  font-size: rpx(28);
}

.body {
  width: 100%;
  img {
    max-width: 100% !important;
  }
}
