@import "src/Components/SCSS/mixins";

.root {
  height: 100%;
}

.container {
  width: rpx(654);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stepOne {
  width: rpx(80);
  height: rpx(80);
  z-index: 99;

  img {
    width: 100%;
  }
}

.font {
  min-width: rpx(100);
  line-height: rpx(34);
  margin-left: rpx(-14);
  margin-top: rpx(-10);
  text-align: center;
  font-size: rpx(24);
  color: #999999;
}

.lineOne {
  position: absolute;
  left: rpx(76);
  width: rpx(290);
  height: rpx(12);
  background: #EAEAEA;
}

.stepTwo {
  margin-top: rpx(-4);
  width: rpx(80);
  height: rpx(80);
  z-index: 99;
}

.date {
  min-width: rpx(120);
  margin-left: rpx(-24);
  line-height: rpx(28);
  font-size: rpx(20);
  color: #999999;
  text-align: center;
}

.fontRed {
  color: #FF3E16;
}

.fontMargin {
  margin-left: rpx(-20);
}

.lineTwo {
  position: absolute;
  right: rpx(76);
  width: rpx(290);
  height: rpx(12);
  background: #EAEAEA;
}

.whileImg {
  width: rpx(84);
  height: rpx(84);
}

.redImg {
  width: rpx(80);
  height: rpx(80);
}

.lineRed {
  background-image: linear-gradient(to right, #D5000B, #FF8734);
}

.lineLong{
  z-index: 1;
  position: absolute;
  left: rpx(80);
  width: rpx(560);
  height: rpx(12);
  background-image: linear-gradient(to right, #D5000B, #FF8734);
}
