@import "../SCSS/mixins";

.root{
  position: relative;
  padding: rpx(24 * 2) rpx(20 * 2) rpx(36 * 2) rpx(20 * 2);
  width: rpx(290 * 2);
  background-color: #fff;
  border-radius: rpx(5 * 2);

  text-align: center;
}

.img{
  width: rpx(86 * 2);
  height: rpx(86 * 2);
}

.notice{
  text-align: center;
  font-size: rpx(18 * 2);
  color: #E13F3F;
  font-weight: bold;
}

.description{
  padding-top: rpx(8 * 2);
  line-height: rpx(17 * 2);
  text-align: center;
  font-size: rpx(12 * 2);
  color: #888888;
}

.close{
  position: absolute;
  right: rpx(17 * 2);
  top: rpx(17 * 2);
  width: rpx(12 * 2);
  height: rpx(12 * 2);
}
