@import "../../../Components/SCSS/mixins";

.root {
  min-height: 100vh;
  background: #FFF;
}

.paymentDiv {
  padding: 0 rpx(40);
}

.stuInfo {
  line-height: rpx(82);
  font-size: rpx(24);
  color: #666666;

  span:last-child {
    margin-left: rpx(32);
  }
}

.paymentRow {
  height: rpx(180);
  margin-bottom: rpx(30);
  padding: rpx(52) rpx(40);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: rpx(20);
  box-shadow: rpx(0) rpx(0) rpx(16) rgba(0, 0, 0, 0.1);
  background: url("img/rowBg.png") no-repeat center center;
  background-size: cover;
}

.title {
  line-height: rpx(44);
  font-size: rpx(32);
  color: #151515;
  font-weight: 700;
}

.subTitle {
  margin-top: rpx(8);
  line-height: rpx(34);
  font-size: rpx(24);
  color: #BBBBBB;
}

.dividerLine {
  height: rpx(24);
  width: 100%;
  background: #F6F6F6;
}

.button {
  @include beautiful-button(160, 60); //按钮特殊传对应px的数字
  font-size: rpx(28);
}

.greyButton {
  width: rpx(160);
  height: rpx(60);
  background: #D1D1D1;
  color: white;
  border: none;
  border-radius: rpx(30);
  font-size: rpx(28);
}

.tip {
  width: 100%;
  margin: rpx(32) 0 rpx(30) 0;
  line-height: rpx(34);
  display: flex;
  color: #60BA00;
  font-size: rpx(24);

  img {
    width: rpx(28);
    height: rpx(28);
    margin: rpx(2) rpx(10) 0 0;
  }
}

.card {
  width: 100%;
  margin-top: rpx(16);
  padding: rpx(42) rpx(40) rpx(30);
  border-radius: rpx(20);
  position: relative;
  background: #FFF;
  box-shadow: rpx(0) rpx(0) rpx(16) rgba(0, 0, 0, 0.1);
}

.leftLine {
  width: rpx(14);
  top: rpx(-26);
  left: rpx(32);
  position: absolute;
}

.rightLine {
  width: rpx(14);
  top: rpx(-26);
  right: rpx(32);
  position: absolute;
}

.itemHead {
  line-height: rpx(40);
  padding-bottom: rpx(14);
  border-bottom: rpx(1) dashed #DCD2D2;
  margin-bottom: rpx(10);
  display: flex;
  justify-content: space-between;
  color: #999999;
  font-size: rpx(28);
}

.item {
  width: 100%;
  margin-top: rpx(14);
  line-height: rpx(40);
  display: flex;
  justify-content: space-between;
  font-size: rpx(28);
  color: #222;

  div {
    width: 33.333%;
  }
}

.chargeName {
  text-align: left;
}

.money {
  text-align: center;
}

.isPay {
  color: #E02020;
  text-align: right;
}


//弹窗
.toastTip {
  max-width: rpx(448);
  text-align: center;
  color: #222222;
  font-size: rpx(32);
  line-height: rpx(44);

  span {
    display: block;
  }
}


.toastHeight {
  height: rpx(420);
}

.toastBtn {
  margin-top: rpx(52);
  @include beautiful-button(386, 84);
  font-size: rpx(32);
}

