@import "../../../../Components/SCSS/mixins";

.root {
  min-height: 100vh;
  padding: rpx(32);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F6F6;
  position: relative;

}

.header {
  margin-top: rpx(12);
  text-align: center;
}

.titlt {
  line-height: rpx(50);
  margin-bottom: rpx(6);
  color: #333333;
  font-size: rpx(36);
  font-weight: bold;
}

.time {
  line-height: rpx(34);
  font-size: rpx(24);
  color: #888;
}

.avater {
  width: rpx(288);
  height: rpx(248);
  padding: rpx(26) rpx(46);
  background: url("./img/photoFrame.png") no-repeat center center;
  background-size: rpx(288) rpx(248);
  position: absolute;
  top: rpx(178);
  left: rpx(232);
}

.img {
  width: rpx(196);
  height: rpx(196);
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}


.card {
  width: rpx(684);
  min-height: rpx(616);
  margin-top: rpx(168);
  border-radius: rpx(20);
  overflow: hidden;
  background-color: #FFF;
}

.stuInfo {
  padding-top: rpx(82);
  min-height: rpx(530);
}

.checked {
  position: absolute;
  top: rpx(690);
  right: rpx(48);

  img {
    width: rpx(204);
    height: rpx(184);
  }
}

.btn {
  margin-top: rpx(52);
}

.button {
  @include beautiful-button(288, 84);
  font-size: rpx(32);
}
