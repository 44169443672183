@import "src/Components/SCSS/mixins";

.root {
  width: 100%;
  position: relative;

  :global{
    .za-input--disabled input, .za-input--disabled textarea{
      color: #666666;
      -webkit-text-fill-color: #666666;
    }
  }
}

.close {
  position: absolute;
  top: rpx(72);
  left: rpx(32);
  width: rpx(26);
  height: rpx(24);
}

.container {
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
}

.content {
  margin-top: rpx(176);
  background: #FFF;
  width: 100%;
  border-radius: rpx(20);

}

.baseInfo {
  padding: rpx(38) rpx(64) rpx(24) rpx(32);
  overflow: hidden;
}

.title {
  line-height: rpx(40);
  font-size: rpx(28);
  color: #333;
  display: flex;
  align-items: center;
}

.line {
  width: rpx(8);
  height: rpx(24);
  margin-right: rpx(10);
  display: inline-block;
  border-radius: rpx(4);
  background-image: linear-gradient(#FF8734, #D5000B);
}

.formContent {
  position: relative;
  border-left: rpx(2) dashed #FF3E16;
  padding-left: rpx(30);

  &:before {
    content: "";
    position: absolute;
    left: rpx(-6);
    top: 0;
    width: rpx(10);
    height: rpx(50);
    background: #FFFFFF;
  }
}


.name {
  line-height: rpx(22 * 2);
  min-width: rpx(140);
  font-size: rpx(28);
  color: #333333;

  span {
    vertical-align: middle;
  }
}

.minWidth{
  min-width: rpx(420);
}

.value{
  color: #666666;
  line-height: rpx(48);
}

.cell {
  position: relative;

  &:last-child {
    border: none;
    &:after {
      content: "";
      position: absolute;
      left: rpx(-38);
      top: rpx(58);
      width: rpx(10);
      height: 100%;
      background: #FFFFFF;
    }
  }

  &:before {
    content: "";
    left: rpx(-38);
    top: rpx(44);
    width: rpx(14);
    height: rpx(14);
    position: absolute;
    background: url("./img/squareIcon.png") no-repeat center center;
    background-size: rpx(14) rpx(14);
  }
}

.required {
  padding-right: 15px;
  background: url("img/red.png") right center no-repeat;
  background-size: rpx(8 * 2);
}

.divided {
  height: rpx(20);
  background: #F6F6F6;
}

.textArea {
  display: block !important;
}

.input {
  margin-top: rpx(30);
}

.large {
  flex: 2 0 auto !important;
}

.all {
  width: rpx(622);
}

.beizhu{
  font-size: rpx(24);
}

.preview {
  width: rpx(392);
  height: rpx(84);
  margin: rpx(60) auto rpx(108);
  background: url("./img/buttonBg.png") no-repeat center;
  background-size: 100%;
  line-height: rpx(84);
  text-align: center;
  color: #FF3E16;
  font-size: rpx(32);
  font-weight: bold;
}

.picContainer {
  display: flex;
}

.picItem {
  width: rpx(186);
  height: rpx(186);
  margin-top: rpx(12);
  border-radius: rpx(20);
  overflow: hidden;
  background: #ccc;
  margin-right: rpx(32);
}

.img {
  width: 100%;
  height: 100%;
}

.family {
  display: flex;
  flex-flow: wrap;
  justify-content: left;
}

.familyItem {
  width: rpx(294);
  height: rpx(190);
  overflow: hidden;
  border-radius: rpx(20);
  background: url("./img/bgCard.png");
  background-size: 100%;
  margin-top: rpx(32);
  margin-left: rpx(32);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.familName{
  line-height: rpx(44);
  padding: 0 rpx(5 * 2);
  text-align: center;
  font-size: rpx(28);
  color: #333;
}

.familyCall{
  padding: 0 rpx(16);
  margin-top: rpx(3);
  line-height: rpx(36);
  font-size: rpx(22);
  color: #FE8141;
  background-color: #FFF5DA;
  border-radius: rpx(8);
}

.info{
  color: #666666;
  margin-top: rpx(20);
  line-height: rpx(40);
  text-align: left;
  font-size: rpx(26);
}
