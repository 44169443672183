@import "src/Components/SCSS/mixins";

.root {
  :global {
    .za-input input {
      text-align: right;
    }
  }
}

.title {
  line-height: rpx(34);
  margin-top: rpx(20);
  margin-left: rpx(20);
  font-size: rpx(24);
  color: #333;
  display: flex;
  align-items: center;
}

.line {
  width: rpx(8);
  height: rpx(24);
  margin-right: rpx(10);
  display: inline-block;
  border-radius: rpx(4);
  background-image: linear-gradient(#FF8734, #D5000B);
}

.container {
  margin-top: rpx(32);
  width: rpx(686);
  background: #fff;
  border-radius: rpx(20);
  padding: 0 rpx(32);
}


.name {
  line-height: rpx(22 * 2);
  font-size: rpx(28);
  color: #333333;
  min-width: rpx(220);

  span {
    vertical-align: middle;
  }
}

.value {
  color: #666666;
  line-height: rpx(48);
}

.button {
  display: flex;
  justify-content: center;
}

.btn1 {
  margin-top: rpx(60);
  margin-right: rpx(30);
  @include beautiful-button(268, 84);
  font-size: rpx(32);
}

.btn2 {
  margin-top: rpx(60);
  @include already-button(268, 84);
  font-size: rpx(32);
  color: #FF3E16;
}

.tips {
  margin: rpx(60) rpx(88) 0;
  line-height: rpx(34);
  font-size: rpx(24);
  color: #999999;
  display: flex;
  text-align: center;

  .img {
    width: rpx(12);
    height: rpx(12);
    margin-top: rpx(10);
  }
}


.buttonDiv {
  position: absolute;
  right: rpx(32);
}

.yulanButton {
  @include beautiful-button(134, 56);
  font-size: rpx(28);
}
