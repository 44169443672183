@import "../../../../Components/SCSS/mixins";

.root{
  width: 100%;
  padding: rpx(15 * 2) 0 rpx(15 * 2) 0;
  text-align: center;
}

.button{
  @include beautiful-button(215 * 2, 40 * 2);//按钮特殊传对应px的数字
  font-size: rpx(16 * 2);
  color: #ffffff;
}

//空心按鈕
.alreadyButton {
  margin: 0 auto;
  color: #E13F3F;
  @include already-button(430, 84);
  font-size: rpx(32);
  background-color: #F6F6F6;
}
