@import "src/Components/SCSS/mixins";

.root{
  padding: 0 rpx(16 * 2) rpx(74) rpx(16 * 2);
  background-color: #F6F6F6;
  min-height:100vh;

  :global{
    .za-input input{
      text-align: right;
    }
    .za-date-select__input {
      padding: 0 rpx(30) 0 0;
    }

    .za-date-select--arrow .za-date-select__input:after {
      top: rpx(18);
      transform: rotate(315deg);
    }

    .za-select__input {
      padding: 0 rpx(30) 0 0;

    }

    .za-select--arrow .za-select__input:after {
      top: rpx(18);
      transform: rotate(315deg);
    }

  }
}

.notice{
  color: #FF0000;
  font-size: rpx(12 * 2);
  //line-height: rpx(49 * 2);
  padding: rpx(36) 0;

  span{
    padding-left: rpx(6 * 2);
    vertical-align: middle;
  }

  img{
    margin-left: rpx(7 * 2);
    width: rpx(6 * 2);
    vertical-align: middle;
  }
}

.cell{
  &:last-child{
    border: none;
  }
}

.dashed{
  border-bottom: 1px dashed #888888!important;
}

.row{
  position: relative;
  width: 100%;
  text-align: right;
}

.name{
  line-height: rpx(22 * 2);
  font-size: rpx(16 * 2);
  color:#333333;;

  span{
    vertical-align: middle;
  }
}

.desName {
  position: relative;
}

.desIcon {
  position: absolute;
  left: rpx(270);
  top: rpx(15);
  width: rpx(32);
  height: rpx(32);
}

.input{
  text-align: right;
}

.required{
  padding-right: 15px;
  background: url("img/red.png") right center no-repeat;
  background-size: rpx(8 * 2);
}

.large{
  flex: 2 0 auto!important;
}

.button{
  @include beautiful-button(215 * 2, 40 * 2);//按钮特殊传对应px的数字
  font-size: rpx(16 * 2);
  color: #ffffff;

  &Wrapper{
    padding: rpx(15 * 2) 0 rpx(42 * 2) 0;
    text-align: center;
  }
}

.select{
  border: none;
  font-size: rpx(14 * 2);
}

.date1111Select {
  padding-right: rpx(20);
}

//成功弹窗
.successPop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);

  .popDiv {
    width: rpx(580);
    height: rpx(376);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("./img/popBg.png")no-repeat center center;
    background-size: rpx(580) rpx(376);
    img {
      position: absolute;
      top: rpx(34);
      right: rpx(34);
      width: rpx(24);
      height: rpx(26);
    }
  }

  .popModifyDiv {
    width: rpx(580);
    height: rpx(376);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: url("./img/popModifyBg.png")no-repeat center center;
    background-size: rpx(580) rpx(376);
    img {
      position: absolute;
      top: rpx(34);
      right: rpx(34);
      width: rpx(24);
      height: rpx(26);
    }
  }
}
.tip {
  margin-top: rpx(-12);
  font-size: rpx(24);
  color: #E13F3F;
  line-height: rpx(36);
}

.toastContent {
  padding: rpx(20) rpx(52) rpx(68) rpx(52);

  .toastRow {
    position: relative;
    margin-bottom: rpx(12);
    font-size: rpx(28);
    color: #222222;
    line-height: rpx(40);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: rpx(24);
      height: rpx(24);
      border-radius: 50%;
      background: #FFE4D3;
      z-index: -1;
    }
    span {
      display: inline-block;
      width: rpx(40);
    }
  }

  .buttonDiv {
    width: 100%;
    text-align: center;

  }
  .button{
    margin-top: rpx(24);
    @include beautiful-button(366, 84);//按钮特殊传对应px的数字
    font-size: rpx(32);
  }
}

.custom-content, .customArrowContent {
  padding: rpx(30) rpx(30);
  background: #fff;
  width: rpx(356);
  height: rpx(230);
  border-radius: rpx(16);
  box-shadow: 0 rpx(2) rpx(8) 0 rgba(173, 173, 173, 0.3);
  color: #333;
  font-size: rpx(24);
  line-height: rpx(34);
  color: #555555;
}

:global {
  .za-popper__arrow {
    position: absolute;
    border-color: transparent;
    border-style: solid;
    width: rpx(5);
    height: rpx(5);
    bottom: rpx(-8);
    border-width: rpx(8) rpx(8) 0;
    border-top-color: #FFFFFF;
  }
}

