@import "../../../Components/SCSS/mixins";

.root {
  padding-top: rpx(26);
  min-height: 100vh;
  background: #F6F6F6;
}

.container {
  height: 100%;
  padding: 0 rpx(32);
}

.progress {
  height: rpx(106);
  margin:0 rpx(14) rpx(40);
}

.tuitionDiv {
  margin-top: rpx(40);
  background: #FFF;
  border-radius: rpx(20);
  overflow: hidden;
}

.card {
  padding: rpx(42) rpx(32) rpx(64);
}

.title {
  line-height: rpx(34);
  margin-bottom: rpx(32);
  display: flex;
  align-items: center;
  color: #888888;
  font-size: rpx(24);
}

.line {
  width: rpx(6);
  height: rpx(22);
  margin-right: rpx(10);
  display: inline-block;
  border-radius: rpx(3);
  background-image: linear-gradient(#FF8734, #D5000B);
}

.content {
  display: flex;
  justify-content: left;
}

.message {
  width: rpx(186);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: rpx(32);
}

.materialPic {
  position: relative;
  width: rpx(186);
  height: rpx(186);
  border-radius: rpx(20);
  background: url("./img/camera.png") no-repeat center center #EFEFEF;
  background-size: rpx(60);
  overflow: hidden;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: rpx(186);
    height: rpx(186);
  }
}

.showImg{
  width: 100%;
  height: 100%;
  z-index: 9;
  border: none;
}

.deleteImg{
  position: absolute;
  top: rpx(10);
  right: rpx(10);
  width: rpx(40);
  height: rpx(40);
  opacity: 0.5;
  z-index: 99;
}

.simpleBtn {
  line-height: rpx(42);
  padding: 0 rpx(20);
  border-radius: rpx(8);
  color: #FF8E25;
  font-size: rpx(24);
  background: #FFF2E6;
  text-align: center;
  margin-top: rpx(36);
}


.button {
  margin: rpx(120) rpx(160);
  @include beautiful-button(366, 84); //按钮特殊传对应px的数字
  font-size: rpx(32);
}

