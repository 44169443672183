@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.toastDiv {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0,0,0,0.7);
}

.toastContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: rpx(620);
  height: rpx(820);
  background: url("./img/bg.png")no-repeat center center;
  background-size: rpx(620) rpx(820);
}

.clostIcon {
  position: fixed;
  right: rpx(64);
  top: rpx(168);
  width: rpx(66);
  height: rpx(66);
  background: url("./img/close.png")no-repeat center center;
  background-size: rpx(66) rpx(66);
  z-index: 2;
}

.toastButton {
  margin: rpx(662) auto 0 auto;
  width: rpx(462);
  height: rpx(82);
  background: url("./img/button.png")no-repeat center center;
  background-size: rpx(462) rpx(82);
}
