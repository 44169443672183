@import "../../Components/SCSS/mixins";

.root {
  width: 100%;
}

.container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.6);
}

.bottomPopDiv {
  position: fixed;
  padding: rpx(16);
  left: 0;
  bottom: 0;
  width: 100%;
  background: #FFFEFA;
  border-radius: rpx(40) rpx(40) 0 0;
}

.bottomContent {
  position: relative;
  background: #FFFFFF;
  border-radius: rpx(40) rpx(40) 0 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 rpx(12) 0 rgba(255, 168, 108, 0.3);
}

.title {
  position: absolute;
  width: rpx(362);
  height: rpx(90);
  top: rpx(-44);
  left: 50%;
  transform: translateX(-50%);
  background: url("./img/titleBg.png")no-repeat center center;
  background-size: rpx(362) rpx(90);
  line-height: rpx(90);
  font-size: rpx(40);
  text-align: center;
  color: #FFFFFF;
}
