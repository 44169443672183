@import "src/Components/SCSS/mixins";

.root {
  width: 100%;
  background: #FFF;
  position: relative;
  border-top-left-radius: rpx(40);
  border-top-right-radius: rpx(40);
  box-shadow: 0 rpx(-6) rpx(20) 0 rgba(0, 0, 0, 0.06);
}

.top {
  padding: rpx(60) rpx(60) 0 rpx(32);
}

.title {
  line-height: rpx(40);
  font-size: rpx(28);
  color: #333;
  display: flex;
  align-items: center;
}

.line {
  width: rpx(8);
  height: rpx(24);
  margin-right: rpx(10);
  display: inline-block;
  border-radius: rpx(4);
  background-image: linear-gradient(#FF8734, #D5000B);
}

.formContent {
  margin: rpx(24) 0;
  padding-left: rpx(30);
  position: relative;
  border-left: rpx(2) dashed #FF3E16;

  &:before {
    content: "";
    position: absolute;
    left: rpx(-6);
    top: 0;
    width: rpx(10);
    height: rpx(50);
    background: #FFFFFF;
  }
}

.name {
  line-height: rpx(44);
  font-size: rpx(28);
  color: #333333;

  span {
    vertical-align: middle;
  }
}

.required {
  padding-right: 15px;
  background: url("img/red.png") right center no-repeat;
  background-size: rpx(8 * 2);
}

.cell {
  position: relative;

  &:last-child {
    border: none;
  }

  &:before {
    content: "";
    left: rpx(-38);
    top: rpx(44);
    width: rpx(14);
    height: rpx(14);
    position: absolute;
    background: url("./img/circle.png") no-repeat center center;
    background-size: rpx(14) rpx(14);
  }
}

.large{
  flex: 2 0 auto!important;
}


.input {
  text-align: right;
}


.refCell {
  &:after {
    content: "";
    position: absolute;
    left: rpx(-38);
    top: rpx(58);
    width: rpx(10);
    height: rpx(50);
    background: #FFFFFF;
  }
}

.hr {
  width: rpx(636);
  height: rpx(2);
  background: #EFEFEF;
}

.divided {
  height: rpx(20);
  background: #F6F6F6;
}

.bottom {
  padding: rpx(32) rpx(60) 0 rpx(32);
}

.textArea{
  display: block!important;
}

.input{
  margin-top: rpx(30);
}

.refCellLength{
  &:after {
    content: "";
    position: absolute;
    left: rpx(-38);
    top: rpx(58);
    width: rpx(10);
    height: rpx(200);
    background: #FFFFFF;
  }
}

.buttonDiv {
  background: #FFFFFF;
  padding: rpx(30 * 2) 0 rpx(42 * 2) 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.button {
  @include beautiful-button(286, 84);
  font-size: rpx(32);
}

.reRoot {
  padding: 0;
}

.reButton {
  @include beautiful-button(286, 84);
  font-size: rpx(32);
}
