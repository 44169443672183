@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.toastDiv {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 15;
  background: rgba(0,0,0,0.7);
}

.toastContent {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: rpx(638);
  height: rpx(710);
  background: url("./img/在线报到bg.png")no-repeat center center;
  background-size: rpx(638) rpx(710);
}

.toastButton {
  margin: rpx(582) auto 0 auto;
  width: rpx(620);
  height: rpx(82);
  text-align: center;
}

.button{
  @include beautiful-button(394, 82);//按钮特殊传对应px的数字
  font-size: rpx(32);
}
