@import "src/Components/SCSS/mixins";

.container {
  position: relative;
  width: 100%;
  padding: rpx(60) rpx(32) 0 rpx(32);
  border-radius: rpx(40) rpx(40) 0 0;
  box-shadow: 0 rpx(-6) rpx(20) 0 rgba(0, 0, 0, 0.06);
  min-height: rpx(200);
  background: #FFFFFF;
}

.title {
  position: relative;
  padding-left: rpx(24);
  line-height: rpx(40);
  color: #333333;
  font-size: rpx(28);

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: rpx(8);
    width: rpx(8);
    height: rpx(24);
    border-radius: rpx(8);
    background: linear-gradient(to bottom, #FF8734, #D5000B);
  }
}

.tip {
  margin-top: rpx(8);
  font-size: rpx(24);
  line-height: rpx(34);
  color: #FF0000;
}

.formContent {
  margin-top: rpx(54);
  position: relative;
  border-left: rpx(2) dashed #FF3E16;
  padding-left: rpx(30);

  &:before {
    content: "";
    position: absolute;
    left: rpx(-6);
    top: 0;
    width: rpx(10);
    height: rpx(10);
    background: #FFFFFF;
  }
}

.cell{
  position: relative;

  &:before {
    content: "";
    left: rpx(-38);
    top: rpx(10);
    width: rpx(14);
    height: rpx(14);
    position: absolute;
    background: url("./img/squareIcon.png")no-repeat center center;
    background-size: rpx(14) rpx(14);
  }
  &:last-child {
    &:after {
      content: "";
      position: absolute;
      left: rpx(-38);
      top: rpx(24);
      width: rpx(10);
      height: rpx(380);
      background: #FFFFFF;
    }
  }
}


.cellTitle {
  font-size: rpx(28);
  line-height: rpx(44);
  color: #333333;
}

.imgListDiv {
  padding: rpx(20) 0 rpx(40) 0;
}

.buttonDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: rpx(60) 0 rpx(74) 0;
}

.button {
  @include beautiful-button(286, 84);
  font-size: rpx(32);
}
