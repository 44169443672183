@import "src/Components/SCSS/mixins";


.container {
  width: 100%;
}

.headInfo {
  position: relative;
  width: 100%;
  height: rpx(230);
}

.headImage {
  float: left;
  position: relative;
  padding: rpx(16);
  width: rpx(202);
  height: rpx(230);
  background: url("../../img/headInfoBg.png")no-repeat center center;
  background-size: 100% 100%;

  img {
    border-radius: rpx(12);
    width: 100%;
    height: 100%;
  }
}

.headContent {
  float: right;
  overflow: hidden;
  min-width: rpx(460);
}

.headName {
  display: inline-block;
  width: rpx(460);
  margin-top: rpx(30);
  margin-bottom: rpx(10);
  line-height: rpx(50);
  font-size: rpx(32);
  color: #ffffff;
  text-align: left;
}

.longName {
  font-size: rpx(20);
}

.number {
  font-size: rpx(20);
  padding: rpx(2) rpx(8);
  border: rpx(1) solid #FFFFFF;
  border-radius: rpx(6);
  margin-left: rpx(12);
}

.headFont {
  width: rpx(460);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: rpx(24);
  line-height: rpx(34);
  color: #ffffff;
  text-align: left;
}

.headButtonDiv {
  margin-top: rpx(22);
  display: flex;
  flex-wrap: nowrap;

  .headButton {
    margin-right: rpx(12);
    position: relative;
    width: rpx(140);
    height: rpx(44);
    line-height: rpx(44);
    text-align: center;
    font-size: rpx(24);
    color: #ffffff;
    border-radius: rpx(8);
    border: 1px solid #ffffff;

    &:last-child{
      margin-right: rpx(20);
    }
  }

  .headButtonYesBefore {
    //width: rpx(56);
    padding: 0 rpx(8);
    height: rpx(32);
    line-height: rpx(32);
    border: 1px solid #ffffff;
    right:rpx(-20);
    top: rpx(-26);
    position: absolute;
    border-radius: rpx(16);
    background: #ffffff;
    font-size: rpx(20);
    color: #FF5420;
  }

  .headButtonNoBefore {
    padding: 0 rpx(8);
    height: rpx(32);
    line-height: rpx(32);
    right:rpx(-20);
    top: rpx(-26);
    position: absolute;
    border-radius: rpx(16);
    border: 1px solid #ffffff;
    background: #FF5420;
    font-size: rpx(20);
    color: #ffffff;

  }
}

.signInDiv {
  position: absolute;
  bottom: rpx(8);
  right: rpx(10);
  z-index: 3;

  img {
    width: rpx(92);
    height: rpx(84);
  }
}
